.blog-container {
  min-height: calc(100vh - 64px);
  background: 
    linear-gradient(120deg, rgba(37, 99, 235, 0.1) 0%, rgba(79, 70, 229, 0.1) 100%),
    radial-gradient(circle at top right, rgba(99, 102, 241, 0.15) 0%, transparent 50%),
    radial-gradient(circle at bottom left, rgba(37, 99, 235, 0.15) 0%, transparent 50%),
    linear-gradient(to bottom right, #eff6ff, #eef2ff);
  position: relative;
  padding: 2rem 0;
}

.blog-container::before,
.blog-container::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.5;
}

.blog-container::before {
  background: rgba(37, 99, 235, 0.08);
  top: 20%;
  right: -100px;
}

.blog-container::after {
  background: rgba(79, 70, 229, 0.08);
  bottom: 20%;
  left: -100px;
}

.blog-content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
}

.blog-intro {
  margin-bottom: 4rem;
  max-width: 800px;
}

.blog-intro-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  background: linear-gradient(to right, #2563eb, #4f46e5);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.blog-intro-text {
  font-size: 1.125rem;
  line-height: 1.75;
  color: #4b5563;
}

.blog-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(37, 99, 235, 0.1);
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.blog-card article {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.5rem;
  align-items: center;
}

.blog-card-image {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.75rem;
  overflow: hidden;
  background: rgba(37, 99, 235, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2563eb;
  padding: 0.75rem;
}

.blog-card-icon svg {
  width: 100%;
  height: 100%;
}

.blog-card-content {
  min-width: 0;
}

.blog-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  position: relative;
}

.blog-card-title .title-text {
  background: linear-gradient(120deg, #2563eb 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: inline-block;
}

.blog-card-title .title-text::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: linear-gradient(120deg, #2563eb 0%, #4f46e5 100%);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-card-title .title-text::after {
  transform: scaleX(1);
}

.blog-card-meta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.blog-card-date {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.75rem;
  color: #6b7280;
  padding: 0.25rem 0.5rem;
  background: rgba(37, 99, 235, 0.1);
  border-radius: 0.5rem;
  white-space: nowrap;
}

.blog-card-excerpt {
  color: #4b5563;
  line-height: 1.5;
  margin-top: 0.75rem;
}

.blog-card-arrow {
  color: #2563eb;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.blog-card:hover .blog-card-arrow {
  opacity: 1;
  transform: translateX(0);
} 