.footer {
  background: linear-gradient(to bottom, #1f2937, #111827);
  color: #fff;
  padding: 4rem 0 1rem;
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.footer-section {
  flex: 0 1 auto;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #fff;
  position: relative;
  display: inline-block;
}

.footer-section h4::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 2rem;
  height: 2px;
  background: linear-gradient(to right, #2563eb, #4f46e5);
}

.footer-section a {
  color: #cbd5e1;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
}

.footer-section a:hover {
  color: #fff;
  transform: translateX(5px);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: #cbd5e1;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: #fff;
  transform: translateY(-3px);
}

.social-links a svg,
.footer-section p svg {
  margin-right: 8px;
  vertical-align: middle;
}

.social-links a:hover svg {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.footer-bottom {
  margin-top: 3rem;
  padding-top: 1.5rem;
  text-align: center;
  position: relative;
}

.footer-bottom::before {
  content: '';
  position: absolute;
  top: 0;
  left: 20%;
  right: 20%;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
}

.footer-bottom p {
  color: #94a3b8;
  font-size: 0.9rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer {
    padding: 2rem 0 1rem;
  }

  .footer-content {
    flex-direction: column;
    gap: 2rem;
    padding: 0 1.5rem;
  }

  .footer-section {
    text-align: left;
    margin-bottom: 1.5rem;
  }

  .footer-section nav,
  .social-links {
    align-items: flex-start;
  }
}
