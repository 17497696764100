.dragon-slayer-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

h1 {
    color: #2c3e50;
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

h2 {
    color: #2c3e50;
    font-size: 2rem;
    margin: 2rem 0 1rem;
}

h3 {
    color: #34495e;
    font-size: 1.5rem;
    margin: 1.5rem 0 1rem;
}

.tagline {
    font-size: 1.25rem;
    color: #7f8c8d;
    margin-bottom: 2rem;
}

.description p {
    line-height: 1.6;
    margin-bottom: 1rem;
}

.play-store-link {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 4px;
    margin: 1rem 0;
    transition: background-color 0.3s ease;
}

.play-store-link:hover {
    background-color: #45a049;
}

.privacy-policy {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
}

.policy-content {
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 8px;
}

.policy-content p {
    line-height: 1.6;
    margin-bottom: 1rem;
}

.policy-content ul {
    margin: 1rem 0;
    padding-left: 2rem;
}

.policy-content li {
    margin-bottom: 0.5rem;
}

.last-updated {
    font-style: italic;
    color: #7f8c8d;
    margin-top: 2rem;
}

.game-logo {
    max-width: 300px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.privacy-link {
    display: block;
    color: #2c3e50;
    text-decoration: underline;
    margin-top: 1rem;
    transition: color 0.3s ease;
}

.privacy-link:hover {
    color: #34495e;
}
