.hero-section {
  text-align: center;
  margin-bottom: 4rem;
  padding: 2rem;
  background: linear-gradient(to right, rgb(239, 246, 255), rgb(238, 242, 255));
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, rgb(37, 99, 235), rgb(79, 70, 229));
}

.hero-subtitle {
  font-size: 1.5rem;
  color: rgb(75, 85, 99);
  margin-bottom: 1.5rem;
  font-weight: 300;
}

.hero-description {
  color: rgb(75, 85, 99);
  max-width: 42rem;
  margin: 0 auto;
  line-height: 1.625;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}

@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }
}

.skill-card {
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.1);
  transition: box-shadow 0.3s ease;
  border: 1px solid rgb(243, 244, 246);
}

.skill-card:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.skill-icon-wrapper {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.skill-icon-wrapper.blue {
  background-color: rgb(239, 246, 255);
}

.skill-icon-wrapper.indigo {
  background-color: rgb(238, 242, 255);
}

.skill-icon-wrapper.purple {
  background-color: rgb(250, 245, 255);
}

.skill-icon {
  font-size: 1.5rem;
}

.skill-icon.blue {
  color: rgb(37, 99, 235);
}

.skill-icon.indigo {
  color: rgb(79, 70, 229);
}

.skill-icon.purple {
  color: rgb(124, 58, 237);
}

.skill-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: rgb(31, 41, 55);
}

.skill-list {
  color: rgb(75, 85, 99);
}

.skill-list > * + * {
  margin-top: 0.5rem;
}

.skill-item {
  display: flex;
  align-items: center;
}

.skill-bullet {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 9999px;
  margin-right: 0.5rem;
}

.skill-bullet.blue {
  background-color: rgb(96, 165, 250);
}

.skill-bullet.indigo {
  background-color: rgb(129, 140, 248);
}

.skill-bullet.purple {
  background-color: rgb(167, 139, 250);
}

.timeline {
  position: relative;
}

.timeline-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgb(191, 219, 254), rgb(199, 210, 254), rgb(216, 180, 254));
}

.timeline-item {
  position: relative;
  margin-bottom: 4rem;
}

.timeline-content {
  display: flex;
  align-items: center;
}

.timeline-left {
  width: 50%;
  padding-right: 2rem;
  text-align: right;
}

.timeline-right {
  width: 50%;
  padding-left: 2rem;
}

.timeline-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgb(31, 41, 55);
}

.timeline-company {
  font-weight: 500;
}

.timeline-company.blue {
  color: rgb(79, 70, 229);
}

.timeline-company.purple {
  color: rgb(124, 58, 237);
}

.timeline-date {
  font-size: 0.875rem;
  color: rgb(107, 114, 128);
}

.timeline-icon-wrapper {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timeline-icon-circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.timeline-icon-circle.blue {
  background: linear-gradient(to right, rgb(59, 130, 246), rgb(79, 70, 229));
}

.timeline-icon-circle.purple {
  background: linear-gradient(to right, rgb(124, 58, 237), rgb(79, 70, 229));
}

.timeline-icon {
  color: white;
}

.timeline-description {
  color: rgb(75, 85, 99);
  line-height: 1.625;
}

@media (max-width: 640px) {
  .timeline-content {
    flex-direction: column;
  }
  
  .timeline-left,
  .timeline-right {
    width: 100%;
    text-align: left;
    padding: 0;
  }
  
  .timeline-line {
    left: 1.25rem;
  }
  
  .timeline-icon-wrapper {
    left: 1.25rem;
  }
}

.hero-logo-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.hero-logo {
  width: 120px;
  height: 120px;
  transform: rotate(180deg);
  fill: #0B4F6C;
  transition: all 0.3s ease;
}

.hero-logo:hover {
  transform: rotate(180deg) scale(1.1);
  fill: #4A4E69;
} 