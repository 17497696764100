.home-container {
    min-height: 100vh;
    background: 
        linear-gradient(120deg, rgba(37, 99, 235, 0.1) 0%, rgba(79, 70, 229, 0.1) 100%),
        radial-gradient(circle at top right, rgba(99, 102, 241, 0.15) 0%, transparent 50%),
        radial-gradient(circle at bottom left, rgba(37, 99, 235, 0.15) 0%, transparent 50%),
        linear-gradient(to bottom right, #eff6ff, #eef2ff);
    padding-top: 64px;
    position: relative;
  }
  
  .home-container::before,
  .home-container::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    filter: blur(80px);
    z-index: 0;
  }
  
  .home-container::before {
    background: rgba(37, 99, 235, 0.08);
    top: 20%;
    right: -100px;
  }
  
  .home-container::after {
    background: rgba(79, 70, 229, 0.08);
    bottom: 20%;
    left: -100px;
  }
  
  .content-wrapper {
    max-width: 1024px;
    margin: 0 auto;
    padding: 2rem 1rem;
    position: relative;
    z-index: 1;
  }
  
  .home-header {
    text-align: center;
    margin-bottom: 3rem;
    padding-top: 2rem;
  }
  
  .title {
    font-size: 3.75rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    background: linear-gradient(to right, #2563eb, #4f46e5);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .subtitle {
    font-size: 1.5rem;
    color: #4b5563;
    margin-bottom: 2rem;
  }
  
  .description {
    font-size: 1.125rem;
    color: #4b5563;
    max-width: 42rem;
    margin: 0 auto;
    line-height: 1.75;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  @media (min-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background: white;
    text-decoration: none;
    color: inherit;
  }
  
  .card:hover {
    transform: translateY(-4px);
  }
    
  .card-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }

  .card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-content {
    padding: 1.5rem;
  }

  .card-title {
    margin: 0 0 0.5rem 0;
    font-size: 1.5rem;
  }

  .card-description {
    margin: 0;
    color: #666;
  }    

  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
  }