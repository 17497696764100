.projects-container {
  min-height: calc(100vh - 64px);
  background: 
    linear-gradient(120deg, rgba(37, 99, 235, 0.1) 0%, rgba(79, 70, 229, 0.1) 100%),
    radial-gradient(circle at top right, rgba(99, 102, 241, 0.15) 0%, transparent 50%),
    radial-gradient(circle at bottom left, rgba(37, 99, 235, 0.15) 0%, transparent 50%),
    linear-gradient(to bottom right, #eff6ff, #eef2ff);
  position: relative;
  padding: 2rem 0;
}

.projects-content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
}

.projects-intro {
  margin-bottom: 4rem;
  max-width: 800px;
}

.projects-intro-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  background: linear-gradient(to right, #2563eb, #4f46e5);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.projects-intro-text {
  font-size: 1.125rem;
  line-height: 1.75;
  color: #4b5563;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.project-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(37, 99, 235, 0.1);
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.project-card article {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.5rem;
  align-items: center;
}

.project-card-image {
  width: 5rem;
  height: 5rem;
  border-radius: 0.75rem;
  overflow: hidden;
}

.project-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-card-content {
  min-width: 0;
}

.project-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.project-card-title .title-text {
  background: linear-gradient(120deg, #2563eb 0%, #4f46e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.project-card-tagline {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}

.project-card-description {
  color: #4b5563;
  line-height: 1.5;
}

.project-card-arrow {
  color: #2563eb;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.project-card:hover .project-card-arrow {
  opacity: 1;
  transform: translateX(0);
}
