.header {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(to right, #2563eb, #4f46e5);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  width: auto;
  margin-right: 10px;
  vertical-align: middle;
}

.logo-image {
  width: 40px;
  height: 40px;
  transform: rotate(180deg);
  fill: currentColor;
  animation: spin 4s linear infinite;
}

/* Optional: Pause animation on hover */
.logo-image:hover {
  animation-play-state: paused;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: #1f2937;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #2563eb, #4f46e5);
  transition: width 0.3s ease;
}

.nav-links a:hover::after {
  width: 100%;
}

/* Responsive design */
@media (max-width: 768px) {
  .header {
    padding: 0.75rem 0;
  }

  .header-content {
    padding: 0 1rem;
    flex-direction: row;  /* Keep horizontal layout */
    gap: 1rem;
  }

  .logo a {
    font-size: 1.25rem;  /* Slightly smaller on mobile */
  }

  .nav-links {
    gap: 1.25rem;  /* Slightly closer links */
  }

  .nav-links a {
    font-size: 0.9rem;  /* Smaller text on mobile */
    padding: 0.5rem 0;  /* Add tap target padding */
  }
}

@keyframes spin {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(540deg);
  }
}
