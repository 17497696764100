.blog-post {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2d3748;
    margin-bottom: 0.75rem;
    line-height: 1.2;
  }
  
  .blog-post time {
    color: #718096;
    display: block;
    margin-bottom: 2rem;
    font-size: 0.95rem;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 1.5rem;
  }
  
  .markdown-content {
    line-height: 1.8;
    color: #4a5568;
  }
  
  .markdown-content h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #2d3748;
    margin: 2.5rem 0 1rem;
  }
  
  .markdown-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin: 2rem 0 1rem;
  }
  
  .markdown-content p {
    margin-bottom: 1.5rem;
  }
  
  .markdown-content ul, 
  .markdown-content ol {
    margin: 1.5rem 0;
    padding-left: 2rem;
  }
  
  .markdown-content li {
    margin-bottom: 0.5rem;
  }
  
  .markdown-content code {
    background: #f7fafc;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-size: 0.9em;
    color: #805ad5;
  }
  
  .markdown-content pre {
    background-color: #282c34;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 1rem 0;
    overflow-x: auto;
  }
  
  .markdown-content blockquote {
    border-left: 4px solid #805ad5;
    padding-left: 1rem;
    margin: 1.5rem 0;
    color: #718096;
    font-style: italic;
  }
  
  .markdown-content a {
    color: #805ad5;
    text-decoration: none;
    border-bottom: 1px dotted #805ad5;
  }
  
  .markdown-content a:hover {
    border-bottom-style: solid;
  }
  
  .markdown-content img {
    max-width: 100%;
    border-radius: 8px;
    margin: 2rem 0;
  }
  
  .blog-post-container {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .table-of-contents {
    position: sticky;
    top: 2rem;
    height: fit-content;
    padding: 1rem;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
  .table-of-contents ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .table-of-contents li {
    cursor: pointer;
    padding: 0.25rem 0;
    font-size: 0.9rem;
  }
  
  .table-of-contents li:hover {
    color: #007bff;
  }
  
  @media (max-width: 768px) {
    .blog-post-container {
      grid-template-columns: 1fr;
    }
    
    .table-of-contents {
      display: none; /* Hide on mobile */
    }
  }
  
  .blog-post-image {
    margin: 0 auto 2rem;
    border-radius: 8px;
    overflow: hidden;
    max-width: 600px;
  }
  
  .blog-post-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  