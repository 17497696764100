.poke-search-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.project-logo {
    max-width: 300px;
    height: auto;
    margin-bottom: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
}

.project-logo:hover {
    transform: scale(1.05);
}

h1 {
    color: #1a1a1a;
    font-size: 3rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.tagline {
    font-size: 1.4rem;
    color: #3d405b;
    margin-bottom: 2rem;
    font-weight: 500;
}

.description {
    text-align: left;
    width: 100%;
    line-height: 1.6;
}

.tech-stack {
    margin: 2rem 0;
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.tech-section {
    margin: 1.5rem 0;
    padding: 1.5rem;
    border-radius: 12px;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    transition: transform 0.2s ease;
}

.tech-section:hover {
    transform: translateY(-5px);
}

.tech-section h4 {
    color: #ee1515;  /* Pokemon Red */
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.tech-section ul {
    list-style-type: none;
    padding-left: 0;
}

.tech-section li {
    margin: 0.8rem 0;
    padding-left: 1.8rem;
    position: relative;
    color: #3d405b;
}

.tech-section li:before {
    content: "⚡";  /* Pokemon-themed bullet point */
    position: absolute;
    left: 0;
    color: #ffcb05;  /* Pokemon Yellow */
}

.project-links {
    margin: 2rem 0;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
}

.live-demo-link {
    display: inline-block;
    padding: 1rem 2rem;
    background: linear-gradient(45deg, #ee1515, #ff3d3d);  /* Pokemon Red gradient */
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(238, 21, 21, 0.3);
}

.live-demo-link:hover {
    background: linear-gradient(45deg, #ff3d3d, #ee1515);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(238, 21, 21, 0.4);
}

/* Add some responsive adjustments */
@media (max-width: 768px) {
    .poke-search-container {
        padding: 1rem;
    }

    h1 {
        font-size: 2.5rem;
    }

    .tagline {
        font-size: 1.2rem;
    }

    .tech-section {
        padding: 1rem;
    }
} 