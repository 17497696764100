.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  width: 100%;
  margin-top: 64px;
}

/* Ensure the footer stays at the bottom */
.footer {
  margin-top: auto;
}

/* Basic responsive design */
@media (max-width: 768px) {
  .main-content {
    padding: 1rem;
  }
}
